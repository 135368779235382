import React from "react";
import styles from './CardOefening.module.scss';
import Image from 'components/Global/Image';

const CardOefening = ({ title, image, variant = "light" }) => {
  return (
    <div className={`card ${styles.card} ${variant}`}>
      <div className="card-body p-3 d-flex flex-column-reverse">
        <div className="">
          <div className="h4 m-0" >{title}</div>
        </div>
        <div className={styles.wrapper}>
          <Image 
            src={image} 
            alt=""
            height="200"
            className="card-img"
          />
        </div>
        
      </div>
    </div>
  );
}

export default CardOefening;