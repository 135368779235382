import React from "react";
import { graphql } from "gatsby"

import SEO from "components/Global/SEO/";
import CardOefening from 'components/Oefeningen/CardOefening';

const Oefeningen = ({data}) => {
  
  const pagina = data.pagina;

  const oefeningen = data.oefeningen.nodes;
  let colorIteratior = 0;

  return (
    <>
      <SEO title="Oefeningen" description="Oefeningen" />
      <div className="container mb-5">
        <div className="row">
          <div className="col-md-6">
            <h1 className="text-primary">{pagina.data.titel.text}<span className="text-secondary">.</span></h1>
            <div dangerouslySetInnerHTML={{__html: pagina.data.intro.html}}></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row no-gutters">
          {oefeningen.map((item, key) => {
            
            colorIteratior = colorIteratior + 1;
            if(colorIteratior > 4 || key % 8 === 0) {
              if(key % 8 === 0) {
                colorIteratior = 1;
              } else {
                colorIteratior = 0;
              }
            }

            return (
              <div key={key} className="col-md-3">
                <CardOefening 
                  title={item.data.titel.text}
                  image={item.data.afbeelding}
                  variant={(colorIteratior % 2 === 0 ? 'dark' : 'light') }
                />
              </div>
            )
          })}
        </div>
      </div>
    </>
  );
}

export const query = graphql`
  query {
    pagina: prismicTekstpagina(uid: {eq: "oefeningen"}) {
      data {
        titel {
          text
        }
        intro {
          html
        }
      }
      url
      uid
    }
    oefeningen: allPrismicOefening {
      nodes {
        id
        data {
          titel {
            text
          }
          tekst {
            text
          }
          afbeelding {
            localFile {
              url
            }
          }
        }
      }
    }
  }
`

export default Oefeningen;